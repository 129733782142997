




import { FormTruckingInvoice } from "@/components/TruckingAccountReceivable";
import Vue from "vue";

export default Vue.extend({
  name: "InvoiceEditPage",
  components: {
    FormTruckingInvoice,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
});
